'use client';

import { useState, useEffect } from 'react';
import { API_URL } from 'constants/api';
import { Card } from 'components';

export function MetroStatus() {
  const [status, setStatus] = useState('loading');

  const checkAPIStatus = async () => {
    setStatus('loading');

    try {
      const response = await fetch(`${API_URL}/health`);

      if (response.ok) {
        const { db, api } = await response.json();

        if (!api && !db) {
          setStatus('down');
          return;
        }

        if (api && db) {
          setStatus('operational');
        } else {
          setStatus('partial');
        }
      } else {
        setStatus('down');
      }
    } catch (error) {
      setStatus('down');
    }
  };

  useEffect(() => {
    checkAPIStatus();
  }, []);

  const getStatusInfo = () => {
    switch (status) {
      case 'operational':
        return {
          text: 'Tempos em tempo real',
          icon: '✅',
          color: 'text-green-500',
        };
      case 'partial':
        return {
          text: 'Falha Parcial - Tempos estimados',
          icon: '⚠️',
          color: 'text-yellow-500',
        };
      case 'down':
        return {
          text: 'Serviço Indisponível',
          icon: '❌',
          color: 'text-red-500',
        };
      default:
        return {
          text: 'A verificar estado do serviço...',
          icon: '🔄',
          color: 'text-neutral-500',
        };
    }
  };

  const { text, icon, color } = getStatusInfo();

  const contentStyle = {
    textAlign: 'center',
    padding: 'var(--spacer-xxs)',
    display: 'flex',
    gap: 'var(--spacer-xs)',
    alignItems: 'center',
    justifyContent: 'center',
  };

  return (
    <div className="fixed top-0 left-0 right-0 p-4 bg-black/50 z-50">
      <div className="w-72 mx-auto">
        <Card
          header={
            <div className={`w-full ${color}`} style={contentStyle}>
              <span className="text-3xl">{icon}</span>
              <span className="text-lg font-medium">{text}</span>
            </div>
          }
        />
      </div>
    </div>
  );
}
